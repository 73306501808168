<template>
  <div style="background-color: #fff;">
    <div class="flex flex-between">
      <div>
        <templateTitle title="角色管理" :isBack="false"></templateTitle>
      </div>

      <x-card>
        <div slot="content" class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row>
              <a-col>
                <a-form-item>
                  <a-input
                    v-model="queryParam.name"
                    @change="$refs.table.refresh(true)"
                    allow-clear
                    placeholder="请输入角色名"
                  />
                </a-form-item>
              </a-col>
              <a-col>
                <a-form-item>
                  <a-button
                    @click="$refs.addForm.add(treeList)"
                    type="primary"
                    ghost
                    v-if="hasPerm('companyMag:role:add')"
                  >
                    新建
                  </a-button>
                  <a-button type="primary" ghost @click="$refs.table.refresh(true)">查询</a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </x-card>
    </div>
    <div class="antTable-padding">
      <a-card :bordered="false">
        <s-table
          ref="table"
          bordered
          :columns="columns"
          :data="loadData"
          :alert="true"
          :rowKey="record => record.code"
        >
          <!-- <template slot="operator" v-if="hasPerm('companyMag:role:add')">
            <a-button @click="$refs.addForm.add(treeList)" icon="plus" type="primary" v-if="hasPerm('companyMag:role:add')"
              >新增角色
            </a-button>
          </template> -->
          <s-column title="序号" dataIndex="index" align="center" :width="55">
            <template slot="customRender" slot-scope="text, record, index">
              {{ index + 1 }}
            </template>
          </s-column>
          <span slot="roleType" slot-scope="text">
            {{ typeFilter(text) }}
          </span>
          <span slot="action" slot-scope="text, record">
            <a
              v-if="hasPerm('companyMag:role:edit') && record.code != 'sys_tenant_manager_role'"
              @click="$refs.editForm.edit(record)"
              >编辑</a
            >
            <a-divider
              type="vertical"
              v-if="hasPerm('companyMag:role:edit') && record.code != 'sys_tenant_manager_role'"
            />
            <a-dropdown
              v-if="
                hasPerm('companyMag:role:grantMenu') ||
                  hasPerm('companyMag:role:grantData') ||
                  hasPerm('companyMag:role:delete')
              "
            >
              <a class="ant-dropdown-link">
                更多
                <a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <!-- 功能迁移到 企业管理-角色管理 -->
                <!-- <a-menu-item v-if="hasPerm('companyMag:role:grantMenu')">
                  <a @click="$refs.roleMenuForm.roleMenu(record)">授权菜单 </a>
                  <a @click="$refs.roleMenuDrawer.roleMenu(record)">授权菜单</a>
                </a-menu-item> -->
                <a-menu-item v-if="hasPerm('companyMag:role:grantData')">
                  <a @click="$refs.roleOrgForm.roleOrg(record)">授权数据</a>
                </a-menu-item>
                <a-menu-item v-if="hasPerm('companyMag:role:delete') && record.code != 'sys_tenant_manager_role'">
                  <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => sysRoleDelete(record)">
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </s-table>

        <add-form ref="addForm" @ok="handleOk" />
        <edit-form ref="editForm" @ok="handleOk" />
        <!-- <role-menu-form ref="roleMenuForm" @ok="handleOk" /> -->
        <role-menu-drawer ref="roleMenuDrawer" @ok="handleOk" />
        <role-org-form ref="roleOrgForm" @ok="handleOk" />
      </a-card>
    </div>
  </div>
</template>

<script>
import { STable, XCard } from '@/components'
import { getRolePage, sysRoleDelete, allUserTree } from '@/api/modular/system/roleManage'
import addForm from './addForm'
import editForm from './editForm'
// import roleMenuForm from './roleMenuForm' //原有的tree授权
import roleMenuDrawer from './roleMenuDrawer' //table授权
import roleOrgForm from './roleOrgForm'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import templateTitle from '@/components/templateTitle.vue'
export default {
  components: {
    XCard,
    STable,
    addForm,
    editForm,
    // roleMenuForm,
    roleMenuDrawer,
    roleOrgForm,
    templateTitle
  },

  data() {
    return {
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          width: 70,
          align: 'center',
          customRender: (text, record, index) => index + 1
        },
        {
          title: '角色名',
          dataIndex: 'name'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return getRolePage(Object.assign(parameter, this.queryParam)).then(res => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      typeDictTypeDropDown: [],
      treeList: []
    }
  },

  created() {
    this.userTree()
    this.sysDictTypeDropDown()
    if (
      this.hasPerm('companyMag:role:edit') ||
      this.hasPerm('companyMag:role:grantMenu') ||
      this.hasPerm('companyMag:role:grantData') ||
      this.hasPerm('companyMag:role:delete')
    ) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: {
          customRender: 'action'
        }
      })
    }
  },

  methods: {
    typeFilter(roleType) {
      // eslint-disable-next-line eqeqeq
      const values = this.typeDictTypeDropDown.filter(item => item.code == roleType)
      if (values.length > 0) {
        return values[0].value
      }
    },
    // 拉取组织机构信息
    userTree() {
      console.log(params, '拉取组织机构信息userTree')
      let params = {
        // id:null
      }
      allUserTree().then(res => {
        if (res.code == 200) {
          console.log(res.data, '-------------------------')
          this.treeList = res.data
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 获取字典数据
     */
    sysDictTypeDropDown(text) {
      sysDictTypeDropDown({
        code: 'role_type'
      }).then(res => {
        this.typeDictTypeDropDown = res.data
      })
    },
    sysRoleDelete(record) {
      sysRoleDelete(record)
        .then(res => {
          if (res.success) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          } else {
            this.$message.error('删除失败：' + res.message)
          }
        })
        .catch(err => {
          this.$message.error('删除错误：' + err.message)
        })
    },

    handleOk() {
      this.$refs.table.refresh()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}

::v-deep .ant-card-body {
  padding: 0;
}
</style>

<style lang="scss" scoped>
@import '@/common/scss/formDetails.scss';

.table-operator {
  margin-bottom: 18px;
}

button {
  margin-right: 8px;
}

::v-deep .s-table-tool .s-table-tool-right {
  display: none;
}

.ant-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

::v-deep .ant-card-body {
  padding: 0 !important;
}

::v-deep .table-page-search-wrapper .ant-form-inline .ant-form-item {
  margin-top: 12px;
  margin-bottom: 0;
  margin-left: 10px;
}
::v-deep .ant-table-small.ant-table-bordered {
  border: 0;
}

::v-deep .ant-table-small.ant-table-bordered {
  height: 550px;
}

::v-deep .ant-table-thead > tr > th:last-child {
  border-right: none !important;
}
::v-deep .ant-table-tbody > tr > td:last-child {
  border-right: none !important;
}
/* 去掉右侧边框 */
::v-deep .ant-table-small.ant-table-bordered .ant-table-content {
  border-bottom: 1px solid #e8e8e8;
  border-right: 0px !important;
}

::v-deep .ant-spin-container {
  position: relative;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
::v-deep .ant-table {
  border: 1px solid #e8e8e8 !important;
}
//注意一定要在Table表格下的样式修改，并且要有:global{}包裹
</style>
